'use client';

import { useAuthStore } from '@/stores/auth-store-provider';
import Script from 'next/script';
import { useCallback, useEffect } from 'react';

type Clarity = {
  (command: 'set', key: string, value: string | string[]): void;
  (
    command: 'identify',
    customerId: string,
    customSessionId?: string,
    customPageId?: string,
    friendlyName?: string
  ): void;
  (command: 'consent', consent?: boolean): void;
  (command: 'upgrade', reason: string): void;
  (command: 'event', eventName: string): void;
  (command: 'pause'): void;
  (command: 'resume'): void;
  (command: 'start'): void;
  (command: 'stop'): void;
};

declare global {
  let clarity: Clarity;

  interface Window {
    clarity: Clarity;
  }
}

// Note that environment variables cannot be destructured for client-side usage
// due to the way Next.js handles environment variable exposure. Client-side
// environment variables must be explicitly accessed through `process.env`
// with the appropriate `NEXT_PUBLIC_` prefix.
const CLARITY_KEY = process.env.NEXT_PUBLIC_CLARITY_KEY;

// This is needed otherwise Clarity sdk doesn't initializes
const ClaritySetupScript = `
  (function(window, document, clarityName) {
    window[clarityName] = window[clarityName] || function() {
      (window[clarityName].q = window[clarityName].q || []).push(arguments);
    };
  })(window,document,"clarity");
`;

export function InitializeClarity() {
  const authStore = useAuthStore();
  const user = authStore.useTracked.user();

  const handleUserIdentify = useCallback(() => {
    if (user && window.clarity) {
      // Custom uuid for existing 2.0 users
      // For newly created users, custom uuid and id is same
      // After signup, custom:uuid is set in background thereby takes time and
      // comes up null just after signup
      const userId = user['custom:uuid'] || user.id;
      window.clarity('identify', userId, undefined, undefined, user.name);
    }
  }, [user]);

  const handleLoad = useCallback(() => {
    if (window.clarity) {
      // Reference: https://learn.microsoft.com/en-us/clarity/setup-and-installation/cookie-consent
      window.clarity('consent');
      handleUserIdentify();
    }
  }, [handleUserIdentify]);

  useEffect(() => {
    handleUserIdentify();
  }, [handleUserIdentify]);

  useEffect(
    () => () => {
      if (window.clarity) {
        window.clarity('stop');
      }
    },
    []
  );

  return (
    CLARITY_KEY && (
      <>
        <Script id="clarity-setup">{ClaritySetupScript}</Script>
        <Script
          id="clarity-init"
          strategy="lazyOnload"
          src={`https://www.clarity.ms/tag/${CLARITY_KEY}`}
          onLoad={handleLoad}
        />
      </>
    )
  );
}
